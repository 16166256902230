














































import { Component, Mixins, Watch } from 'vue-property-decorator'

import Select from '@/components/_uikit/controls/Select.vue'
import {
  FilterLessonStatus,
  LessonEntityResource,
  LessonEntityResourceContainer,
  LessonReviewResource, ManagerMasterGroupsMasterGroupIdLessonsGetParams,
  NameValueResource,
} from '@/store/types'
import NotifyMixin from '@/mixins/NotifyMixin'
import { formatDate, getCurrentMonth } from '@/utils/functions'
import MentorEmployeeModule from '@/store/modules/mentor/employee'
import MentorLessonCard from '@/components/cards/MentorLessonCard.vue'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import RatedLessonModal from '@/components/modals/lessons/RatedLessonModal.vue'
import CurrentTimeMixin from '@/mixins/CurrentTimeMixin'

@Component({
  components: {
    MentorLessonCard,
    RatedLessonModal,
    Select,
  },
})
export default class MentorsItemLessons extends Mixins(NotifyMixin, CurrentTimeMixin) {
  private lessons: LessonEntityResource[] = []
  private filter: ManagerMasterGroupsMasterGroupIdLessonsGetParams = {}
  private reviews: LessonReviewResource[] = []
  private showModalRated = false

  private statuses: NameValueResource[] = [
    { name: 'Просмотренные', value: FilterLessonStatus.CHECKED },
    { name: 'Непросмотренные', value: FilterLessonStatus.NOT_CHECKED },
  ]

  private get mentorID () {
    return +this.$route.params.mentorID
  }

  private getRedirectObject (lesson: LessonEntityResource) {
    if (this.currentMasterGroupID) {
      return {
        name: 'manager.education.mentors.item.lessons.item',
        params: {
          groupID: this.currentMasterGroupID.toString(),
          lessonID: lesson.id.toString(),
          mentorId: this.mentorID.toString(),
        },
      }
    }
  }

  private getCanBeReviewed (lesson: LessonEntityResource) {
    return lesson.ratedCount > 0
  }

  private mounted () {
    this.fetchEmployeeMasterGroups()
      .then((response) => {
        if (this.currentMasterGroup && response.length) {
          const currentMonth = getCurrentMonth(this.currentMasterGroup.months)
          if (currentMonth) {
            this.filter = {
              ...this.filter,
              monthId: currentMonth.value as number,
            }
          }
        }
      })
      .catch(this.notifyError)
  }

  private handleReview (lessonId: number) {
    MentorLessonsModule.fetchLessonRating({
      lessonId: lessonId,
      masterGroupId: this.currentMasterGroupID as number,
    })
      .then((response: LessonReviewResource[]) => {
        this.reviews = response
        this.showModalRated = true
      })
      .catch(this.notifyError)

  }

  private fetchEmployeeMasterGroups () {
    return MentorEmployeeModule.fetchEmployeeMasterGroups(this.mentorID)
  }

  private get masterGroups () {
    return MentorEmployeeModule.employeeMasterGroups.map(item => ({ name: item.title, value: item.id }))
  }

  private get currentMasterGroup () {
    return MentorEmployeeModule.currentMasterGroup
  }

  private get currentMasterGroupID () {
    if (this.currentMasterGroup) {
      return this.currentMasterGroup.id
    }
    return null
  }

  private set currentMasterGroupID (value: number | null) {
    if (value !== null) {
      const foundMasterGroup = MentorEmployeeModule.employeeMasterGroups.find(item => item.id === value)
      foundMasterGroup && MentorEmployeeModule.setEmployeeCurrentMasterGroup(foundMasterGroup)
    }
  }

  private get months () {
    return this.currentMasterGroup?.months.map(item => ({
      name: formatDate(item.name, 'LLLL yyyy'),
      value: item.value as number,
    }))
  }

  private fetchLessons () {
    MentorLessonsModule.fetchLessons({
      masterGroupId: this.currentMasterGroupID as number,
      params: this.filter,
    })
      .then((response: LessonEntityResourceContainer[]) => {
        response.forEach(lessonContainer => {
          this.lessons = [...this.lessons, ...lessonContainer.values]
        })
      })
      .catch(this.notifyError)
  }

  @Watch('filter', { deep: true })
  watchFilter () {
    this.lessons = []
    this.fetchLessons()
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupId () {
    const currentMonth = getCurrentMonth(this.months as NameValueResource[])
    if (currentMonth) {
      this.filter = {
        ...this.filter,
        monthId: currentMonth.value as number,
      }
    }
  }
}
