















































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { Route } from 'vue-router/types/router'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'
import ChartLesson from '@/components/charts/ChartLesson.vue'
import Confirmation from '@/components/modals/Confirmation.vue'
import Tag from '@/components/_uikit/Tag.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import { LessonEntityResource } from '@/store/types'
import { parseDateToMilliseconds } from '@/utils/functions'
import AuthModule from '@/store/modules/auth'

@Component({
  components: {
    ButtonArrow,
    ChartLesson,
    Tag,
  },
})
export default class MentorLessonCard extends Mixins(MasterGroupMixin, NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private lesson!: LessonEntityResource

  @Prop({ required: false })
  private isCanSeeReview!: boolean

  @Prop({ default: true })
  private managePersonal!: boolean

  @Prop({ default: null })
  private redirectObject!: Route

  private get isDone () {
    return this.lesson.mastersCount === this.lesson.checkedCount
  }

  private get canEdit() {
    return !this.isDeadlineDone && this.lesson.isWebinar && this.managePersonal
  }

  private get canWatch () {
    return (!this.lesson.isWebinar && this.hasPermission(this.Permission.EDUCATION_LESSON_VIEW)) || (this.lesson.isWebinar && this.hasPermission(this.Permission.EDUCATION_PERSONAL_CREATE))
  }

  private get currentTime() {
    return DictionaryModule.currentTime
  }

  // ToDo Сейчас нельзя понять сколько здесь отзывов - нам нужна это информация, чтобы понимать нужна ли кнопка с отзывамми
  private get isAnyReviews() {
    return this.lesson.checkedCount > 0
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private isDeadlineDone() {
    return parseDateToMilliseconds(this.lesson.deadlineAt) < this.currentTime
  }

  private handleEdit () {
    this.$router.push({
      name: 'manager.education.lessons.item.edit',
      params: {
        groupID: this.currentMasterGroupID.toString(),
        lessonID: this.lesson.id.toString(),
      },
    })
  }

  protected confirmDelete () {
    this.confirm.open(
      'Удаление урока',
      'Вы действительно хотите удалить выбранный урок?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDelete)
      .catch(() => {return})
  }

  private handleDelete () {
    MentorLessonsModule.deleteLesson({
      lessonID: this.lesson.id,
      masterGroupID: this.currentMasterGroupID,
    })
      .then(() => {
        this.notifySuccess('Урок удален')
        this.$emit('delete')
      })
      .catch(this.notifyError)
  }
}
